.banner-container {
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: fixed;
}

.banner-logo-container {
    width: 350px;
    z-index: 100;
    margin-bottom: 80px;
}

.banner-logo {
    width: 100%;
}


/* Option One */

.banner-background-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
}

.banner-background-image {
    height: 100%;
    transform: translateX(-40%);
    
    
}

/* Option Two */


/* .banner-background-container {
    width: 100vw;
    position: absolute;
}

.banner-background-image {
    width: 100%;
    transform: translateY(-5%);
} */

.banner-hamburger-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 100;
}