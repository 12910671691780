.modal {
    position: absolute;
    width: 300px;
    height: 250px;
    border: 2px solid black;
    background-color: white;
    z-index: 10000000;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 25px;
    border-radius: 2px;
}

.modal-heading {
    font-size: 2rem;
    margin-bottom: 15px;
}

.modal-text {
    font-size: 1.5rem;
    text-align: justify;
    margin-top: 5px;
}

.modal-button {
    max-width: 120px;
    width: 50%;
    height: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 1.6rem;
    margin-top: 20px;
}

.modal-email {
    cursor: pointer;
}

.modal-copy-success {
    position: absolute;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    top: 38%;
    background-color: black;
    color: white;
    height: 30px;
    width: 60px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%);
    padding-top: 2px;
}