.aboutme-container {
    padding: 20px;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    url(../../../resources/images/backgroundHorizontal.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 80vh;
}

.aboutme-column-container {
    columns: 1;
}

.aboutme-title {
    text-transform: uppercase;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 5rem;
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
}

.aboutme-profile-img-container {
    width: 90%;
    margin: 0 auto;
}

.aboutme-profile-img {
    width: 100%;
}

.aboutme-occupation {
    text-align: center;
    font-size: 2rem;
    margin-top: 20px;
    text-transform: uppercase;
}

.aboutme-brand-statement {
    margin: auto;
    margin-top: 10px;
    text-align: justify;
    font-size: 1.8rem;
    width: 90%;
}

.aboutme-skills {
    text-align: center;
    font-size: 2rem;
    margin-top: 40px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.aboutme-list-container {
    columns: 2;
}

.aboutme-list-item {
    font-size: 1.8rem;
    width: 100px;
    margin: 0 auto;
    list-style: none;
}

.aboutme-list-style {
    font-size: 2rem;
    margin: 0 2px;
}

.aboutme-resume-button {
    max-width: 120px;
    width: 50%;
    height: 50px;
    margin: 60px auto 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 1.6rem;
}
/* Responsive Designs */

@media only screen and (min-width: 500px) {
    .aboutme-title {
        width: 350px;
    }

    .aboutme-profile-img-container {
        width: 350px;
    }

    .aboutme-brand-statement {
        width: 350px;
    }

    .aboutme-list-container {
        width: 350px;
        margin: 0 auto;
    }
  }

@media only screen and (min-width: 750px) {
    .aboutme-column-container {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        padding: 0 20px;
    }

    .aboutme-profile-img-container {
        width: 100%;
    }

    .aboutme-right-column {
        padding-left: 40px;
        margin-top: 45px;
    }

    .aboutme-occupation {
        text-align: left;
    }

    .aboutme-brand-statement {
        width: 80%;
        margin: 10px 0 0;
    }

    .aboutme-skills {
        text-align: left;
    }

    .aboutme-list-container {
        width: 60%;
        min-width: 200px;
        margin: 0;
    }

    .aboutme-resume-button {
        grid-column-start: 1;
        margin: -20px auto 20px;
    }
    
}

@media only screen and (min-width: 900px) {
    
    .aboutme-column-container {
        grid-template-columns: 300px 2fr;
    }

    .aboutme-right-column {
        max-width: 550px;
    }

    .aboutme-profile-img-container {
        width: 300px;
    }
    
    .aboutme-list-container {
        
        max-width: 250px;
    }
}

