.card-container {
    width: 250px;
    height: 280px;
    margin: 20px auto;
}

.card-image-container {
    width: 220px;
    height: 220px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.44);
    /* box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.44); */
    /* box-shadow: 3px 3px 6px 0px rgba(0,0,0,0.44) */
}

.card-image {
    height: 100%;
    margin-left: 50%;
    transform: translateX(-50%);
    /* 
    Figured out how to center the oversized photo from stackoverflow. Included is the link
    https://stackoverflow.com/questions/14562457/center-oversized-image-in-div/41059954 
    */
}

.card-title {
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 2rem;
    text-align: center;
    margin-top: 10px;
    color: black;
    text-decoration: none;
}