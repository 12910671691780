.contactMe {
    margin: 20px 20px 40px;
}

.contactMe-h1 {
    text-transform: uppercase;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 5rem;
    margin-left: 20px;
    margin-bottom: 5px;
}

.contactMe-h2 {
    margin-left: 20px;
    margin-bottom: 30px;
    font-size: 2.5rem;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.contactMe-form {
    margin-left: 20px;
}

.contactMe-form-group {
    margin: 10px 0;
}

.contactMe-label {
    display: block;
    font-size: 1.8rem;
    margin-bottom: 5px;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.contactMe-input {
    border-radius: 0px;
    border: 0.5px solid #808080;
    height: 50px;
    width: 300px;
    padding: 5px;
    font-size: 1.8rem;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.contactMe-message {
    height: 100px;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.contactMe-submit {
    max-width: 120px;
    width: 50%;
    height: 50px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 1.6rem;
}

.contactMe-loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (min-width: 650px) {
    .contactMe {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .contactMe-left-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }

    .contactMe-h1 {
        width: 200px;
    }

    .contactMe-h2 {
        width: 200px;
        text-align: left;
    }
}