.footer {
    height: 220px;
    font-family: bebas-neue-pro, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.footer-top {
    height: 120px;
    background-color: #f3f3f4;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
}

.footer-more-info {
    font-weight: 400;
    font-size: 1.8rem;
}

.footer-connection-logos-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.footer-logo-container {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0px 10px;
}

.footer-logo-container-medium {
    width: 30px;
}

.connect-logo {
    width: 100%;
    max-height: 100%;
}

.connect-logo:hover {
    filter: invert(0.3);
}

.footer-bottom {
    height: 100px;
    background-color: #231f20;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-name {
    font-size: 1.8rem;
    text-transform: uppercase;
}

.footer-after-name {
    margin: 0 10px;
}

.footer-link {
    text-decoration: none;
    color: white;
    cursor: pointer;
}


