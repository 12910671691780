/* Reset Styles */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* border: 1px solid gainsboro; */
}

html {
  font-size: 10px;
  background-color: rgba(255, 255, 255, 1);
}


