.up-arrow-container {
    width: 50px;
    height: 30px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.up-arrow {
    transform: rotate(90deg) translateX(-30px);
    width: 100%;
}