.header-container {
    padding: 0 20px;
    position: sticky;
    top: 0;
    height: 80px;
    background-color: white;
    z-index: 1;
}

.header-logo-container {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    width: 180px;
    cursor: pointer;
}

.header-logo {
    width: 100%;
}

.back-button-container {
    width: 35px;
    position: absolute;
    top: 5px;
    left: 20px;
    z-index: 9;
}

.back-button {
    width: 100%;
}

.header-hamburger-menu {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 100;
}